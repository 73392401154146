import React from 'react';
import { RouteComponentProps, withRouter, useLocation } from 'react-router';

import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonToggle } from '@ionic/react';
import { calendarOutline, hammer, moonOutline, help, informationCircleOutline, logIn, logOut, mapOutline, peopleOutline, person, personAdd } from 'ionicons/icons';

import { connect } from '../data/connect';
import { setDarkMode } from '../data/user/user.actions';

import './Menu.css'

const routes = {
  appPages: [
    { title: 'Agenda', path: '/tabs/schedule', icon: calendarOutline },
    //{ title: 'Conferencistas', path: '/tabs/speakers', icon: peopleOutline },
    //{ title: 'Mapa', path: '/tabs/map', icon: mapOutline },
    { title: 'GALERIA (Próximamente)', path: '#', icon: informationCircleOutline },
    //{ title: 'POSTERS IESTEC (próximamente)', path: '/#1', icon: informationCircleOutline },
    //{ title: 'EN VIVO', path: '/live', icon: informationCircleOutline }
    //{ title: 'About', path: '/tabs/about', icon: informationCircleOutline }
  ],
  loggedInPages: [
    /*{ title: 'Account', path: '/account', icon: person },
    //{ title: 'Support', path: '/support', icon: help },
    { title: 'Logout', path: '/logout', icon: logOut },
    { title: 'JIC', path: '/jic', icon: informationCircleOutline }*/
  ],
  loggedOutPages: [
    /*{ title: 'Ingresar', path: '/login', icon: logIn },
    //{ title: 'Support', path: '/support', icon: help },
    { title: 'Registro', path: '/signup', icon: personAdd }*/
  ]
};

interface Pages {
  title: string,
  path: string,
  icon: string,
  routerDirection?: string
}
interface StateProps {
  darkMode: boolean;
  isAuthenticated: boolean;
  menuEnabled: boolean;
}

interface DispatchProps {
  setDarkMode: typeof setDarkMode
}

interface MenuProps extends RouteComponentProps, StateProps, DispatchProps { }

const Menu: React.FC<MenuProps> = ({ darkMode, history, isAuthenticated, setDarkMode, menuEnabled }) => {
  const location = useLocation();

  function renderlistItems(list: Pages[]) {
    return list
      .filter(route => !!route.path)
      .map(p => (
        <IonMenuToggle key={p.title} auto-hide="false">
          <IonItem detail={false} routerLink={p.path} routerDirection="none" className={location.pathname.startsWith(p.path) ? 'selected' : undefined}>
            <IonIcon slot="start" icon={p.icon} />
            <IonLabel>{p.title}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ));
  }

  return (
    <IonMenu  type="overlay" disabled={!menuEnabled} contentId="main">
      <IonContent forceOverscroll={false}>
        <IonList lines="none">
          <IonListHeader><img src='/assets/img/ica-slidebox-img-1.png'/></IonListHeader>
        </IonList>
        <IonList lines="none">
          <IonListHeader>Conferencia</IonListHeader>
          {renderlistItems(routes.appPages)}
        </IonList>
        <IonList lines="none">
          <IonListHeader>Opciones</IonListHeader>
          {isAuthenticated ? renderlistItems(routes.loggedInPages) : renderlistItems(routes.loggedOutPages)}
          <IonItem>
            <IonIcon slot="start" icon={moonOutline}></IonIcon>
            <IonLabel>Modo Oscuro</IonLabel>
            <IonToggle checked={darkMode} onClick={() => setDarkMode(!darkMode)} />
          </IonItem>
        </IonList>
        {/*<IonList lines="none">
          <IonListHeader>Tutorial</IonListHeader>
          <IonItem button onClick={() => {
            history.push('/tutorial');
          }}>
            <IonIcon slot="start" icon={hammer} />
            Show Tutorial
          </IonItem>
        </IonList>*/}
      </IonContent>
    </IonMenu>
  );
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    isAuthenticated: state.user.isLoggedin,
    menuEnabled: state.data.menuEnabled
  }),
  mapDispatchToProps: ({
    setDarkMode
  }),
  component: withRouter(Menu)
})
